
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


const reactPlugin: any = new ReactPlugin();

const initAppInsights = (browserHistory: any) => {
    const appInsights: any = new ApplicationInsights({
        config: {
            connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
            maxBatchInterval: 0,
            disableFetchTracking: true,
            autoTrackPageVisitTime: true,
            disableAjaxTracking: true,
            loggingLevelTelemetry: 2,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {
                    history: browserHistory
                }
            }
        }
    });

    appInsights.loadAppInsights();
}

export { initAppInsights, reactPlugin };