import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { hideTooltips, rebuildTooltips } from "../../settings/theme/ThemeTooltip";

const BackButton = () => {
	const history = useHistory();
	const { urlTab } = useParams<{ urlTab: string }>();
	const hasSearchParams = history.location.search !== "";

	useEffect(() => {
		hideTooltips();
		rebuildTooltips();
	}, []);

	const goBack = () => {
		hideTooltips();
		if (urlTab || hasSearchParams) {
			history.go(-3);
		} else {
			history.goBack();
		}
	};

	return (
		<div className="relative xl:absolute top-5 left-5 inline-flex items-center z-30 text-secondary opacity-70 hover:opacity-100 cursor-pointer text-xs" data-tip="Go Back">
			<ArrowCircleLeftIcon className="h-6 w-6" onClick={goBack} />
		</div>
	);
};

export default BackButton;
